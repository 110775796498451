import {TranslateService} from "@ngx-translate/core";

export class CommonUtils {

    static supportedLanguages: Array<string> = Array.of('ru', 'en', 'de', 'tr');

  static cultureMap: Map<string, string> = new Map<string, string>([
    ["en", "en-US"],
    ["ru", "ru-RU"],
    ["tr", "en-US"],
    ["de", "en-US"]
  ]);
  static lang: string;
  static langForHot: string;

    static getLangFromCookie(): string {
        let locale = this.getValueFromCookie('PLAY_LANG');
        if (!locale) {
            locale = this.getLangFromBrowser();
        }
        return locale;
    }

    static getLangFromBrowser(): string  {
        const windowLang: string = window.navigator.language;
        let lang = CommonUtils.supportedLanguages.find(language => windowLang.includes(language));
        return lang || "en";
    }

    static getValueFromCookie(key: string): string {
        const cookie = document.cookie;
        const parts = cookie.split(`; ${key}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    static setLanguagePreference(currentUser: any, translateService: TranslateService): void {
        if (currentUser && currentUser.uiLanguage) {
            translateService.use(currentUser.uiLanguage.toLowerCase())
        } else if (currentUser && currentUser.company.lang) {
            translateService.use(currentUser.company.lang.toLowerCase())
        } else {
            translateService.use(this.getLangFromCookie());
        }
    }

    static formatMoney(amount) {
        amount = this.roundMoney(amount);
        const parts = amount.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        if (parts.length === 1) {
            parts.push('00');
        }
        if (parts[1].length === 1) {
            parts[1] += '0';
        }
        return parts.join('.');
    }

    static roundMoney(amount): number {
        return this.roundDecimal(amount, 2);
    }

    static roundDecimal(number, precision): number {
        if (typeof number !== "number") {
            return NaN;
        }
        let base = Math.pow(10, precision);
        return (Math.round(number * base) / base);
    }


  static getLang(currentUser: any): string {
    if (currentUser?.uiLanguage) {
      return currentUser.uiLanguage.toLowerCase();
    }
    if (currentUser?.company?.lang) {
      return currentUser.company.lang.toLowerCase();
    }
    return this.getLangFromCookie();
  }

  static setLangForHot(currentUser: any) {
    this.lang = this.getLang(currentUser);
    this.langForHot = this.cultureMap.get(this.lang);
  }

  static getHotTableLang() {
    return this.langForHot;
  }
}
