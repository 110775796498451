import {Injectable} from "@angular/core";
import {Person} from "../../cynteka-desktop/_models/person";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class MessagesService {

  messages:any = null;
  messagesWithArgs:any = null;

  httpOptions = {
    responseType:'text'
  };
  constructor(private http: HttpClient) {

  }

  load() {
    return new Promise((resolve, reject) => {
      this.http
        .get('/core/assets/javascripts/playmessages.js?langRand='+ Math.random(), { responseType: 'text'})
        .subscribe(response => {
          let messagesJs = response;
          this.messages = eval(messagesJs).messages;
          // @ts-ignore
          this.messagesWithArgs = window.playMessages;
          resolve(true);
        })
    })
  }
}
